// @flow
import type { MessageDescriptor } from 'react-intl';

const prefix = 'redirect';

export default {
  page: {
    title: ({
      id: `${prefix}.page.title`,
      defaultMessage: `Redirect`,
      description: 'Title shown if the redirect document has no name/title',
    }: MessageDescriptor),
    redirectsTo: ({
      id: `${prefix}.page.redirectsTo`,
      defaultMessage: 'Redirects to:',
      description: 'Prefix for the redirect to link',
    }: MessageDescriptor),
    redirectsFrom: ({
      id: `${prefix}.page.redirectsFrom`,
      defaultMessage: 'Redirects from:',
      description: 'Prefix for the redirect from links',
    }: MessageDescriptor),
    valueNotSet: ({
      id: `${prefix}.page.valueNotSet`,
      defaultMessage: 'not set',
      description: 'Placeholder shown when there is no value set for a field',
    }: MessageDescriptor),
  },
  table: {
    head: {
      path: ({
        id: `${prefix}.table.head.path`,
        defaultMessage: 'Path',
        description: 'Table head for path field',
      }: MessageDescriptor),
      httpStatusCode: ({
        id: `${prefix}.table.head.httpStatusCode`,
        defaultMessage: 'HTTP Status Code',
        description: 'Table head for HTTP Status Code field',
      }: MessageDescriptor),
    },
  },
};
